import { isValid, differenceInMilliseconds } from 'date-fns';

import { setClientTimeOffset } from '_js/store/actions/client-time-offset';
import { getPpiInfo } from '_js/store/actions/api/ppi/fetch';
import { fetchApplication } from '_js/store/actions/api/application/fetch';
import { fetchBidding } from '_js/store/actions/api/bidding/fetch';
import PPI_STATUS from '_js/constants/PpiStatus';

import { acceptBid as APIacceptBid } from '_js/utils/api/API';

export const ACCEPTED_BID_POLLING_DONE = 'ACCEPTED_BID_POLLING_DONE';
export const BID_ACCEPT_TRACKED = 'BID_ACCEPT_TRACKED';
export const SHOULD_REDIRECT_TO_PPI = 'SHOULD_REDIRECT_TO_PPI';
export const RESET_REDIRECT_TO_PPI = 'RESET_REDIRECT_TO_PPI';

export const ppiRedirectReset = () => ({
  type: RESET_REDIRECT_TO_PPI,
});

export const shouldRedirectToPPI = () => ({
  type: SHOULD_REDIRECT_TO_PPI,
});

export const acceptedBidPollingDone = () => ({
  type: ACCEPTED_BID_POLLING_DONE,
});

export const getBids = () => (dispatch, getState) => {
  const {
    application: { id: applicationId },
  } = getState();

  return dispatch(fetchBidding(applicationId)).then((response) => {
    if (!response || !response.headers) {
      return;
    }

    const serverDateTime = new Date(response.headers['x-server-date']);

    if (isValid(serverDateTime)) {
      const clientTimeOffset = differenceInMilliseconds(new Date(), serverDateTime);
      dispatch(setClientTimeOffset(clientTimeOffset));
    }
  });
};

const getAll = (dispatch, applicationId, isInsuranceFeatureEnabled) =>
  Promise.all([dispatch(fetchApplication(applicationId)), dispatch(getBids())]).then(() => {
    if (isInsuranceFeatureEnabled) {
      dispatch(
        getPpiInfo({
          applicationId,
          clearCache: true,
        }),
      );
    }
  });

export const acceptBid =
  (bidId, accountNumber, isInsuranceFeatureEnabled) => async (dispatch, getState) => {
    const {
      application: { id: applicationId },
    } = getState();

    return APIacceptBid(applicationId, bidId, accountNumber).then(async () => {
      await getAll(dispatch, applicationId, isInsuranceFeatureEnabled);
      const {
        ppi: { status },
      } = getState();
      if (isInsuranceFeatureEnabled && status === PPI_STATUS.ELIGIBLE) {
        dispatch(shouldRedirectToPPI());
      }
    });
  };
