import { useEffect } from 'react';
import { useFeatureIsOn, IfFeatureEnabled, useFeatureValue } from '@growthbook/growthbook-react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import TRACKING from '_js/constants/Tracking';
import { isDevelopment, isProduction } from '_js/utils/environment';
import { load, sendEvent } from '_js/utils/3rdparty/gtm';
import { isCrmUser } from '_js/utils/crm';

export const doNotTrack = () => (isCrmUser() && isProduction()) || isDevelopment();

export const gtm = () => {
  if (doNotTrack() || !TRACKING.googleTagManagerId) return;

  load({ id: TRACKING.googleTagManagerId });
};

export const trackEvent = ({ category, action, label, value, nonInteractiveEvent }) => {
  if (doNotTrack()) return;

  sendEvent({
    event: action,
    event_category: category,
    event_label: label,
    value,
    non_interaction: nonInteractiveEvent,
  });

  const eventData = {
    category,
    action,
  };

  if (label) eventData.label = label;
  if (value) eventData.value = value;

  Sentry.addBreadcrumb({
    type: 'default',
    level: 'info',
    category: nonInteractiveEvent ? 'tracking' : 'ui.action',
    message: 'Tracking event',
    data: eventData,
  });
};

export const hotjar = () => {
  if (doNotTrack()) return;
  if (!TRACKING.hotjarId) return;

  /* eslint-disable */
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: TRACKING.hotjarId, hjsv: 5 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
  /* eslint-enable */
};

export const trackPageView = () => {
  if (doNotTrack()) return;
  if (TRACKING.hotjarId && window.hj) {
    window.hj('stateChange', window.location.href);
  }
};

/**
 * Hook that sends an event to Hotjar if a GrowthBook feature is active.
 *
 * @param {string} featureId - The ID of the GrowthBook feature.
 * @param {boolean} isFeatureActive - Whether the feature is active.
 * @param {any} [featureValue=undefined] - The value of the GrowthBook feature (optional).
 */
const useHotjarEvent = (featureId, isFeatureActive, featureValue = undefined) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !window.hj || !isFeatureActive) return;

    const eventString = featureValue !== undefined ? `${featureId}:${featureValue}` : featureId;
    window.hj('event', eventString);
  }, [featureId, isFeatureActive, featureValue]);
};

/**
 * Custom hook that checks if a GrowthBook feature is active and sends an event to Hotjar if it is.
 *
 * @param {string} featureId - The ID of the GrowthBook feature to check.
 * @returns {boolean} - Returns true if the feature is active, false otherwise.
 */
export const useFeatureIsOnWithHotjar = (featureId) => {
  const isFeatureActive = useFeatureIsOn(featureId);
  useHotjarEvent(featureId, isFeatureActive);
  return isFeatureActive;
};

/**
 * Custom hook that checks the value of a GrowthBook feature and sends an event to Hotjar if active.
 *
 * @param {string} featureId - The ID of the GrowthBook feature to check.
 * @param {string} [fallbackFeatureId=undefined] - The fallback value if the feature value is not
 * available.
 * @returns {any} - Returns the value of the feature.
 */
export const useFeatureValueWithHotjar = (featureId, fallbackFeatureId = undefined) => {
  const featureValue = useFeatureValue(featureId, fallbackFeatureId);
  const isFeatureActive = featureValue !== null && featureValue !== undefined;
  useHotjarEvent(featureId, isFeatureActive, featureValue);
  return featureValue;
};

/**
 * Component that checks if a GrowthBook feature is active and sends an event to Hotjar if it is.
 *
 * @param {string} feature - The ID of the GrowthBook feature to check.
 * @param {ReactNode} children - The children components to render if the feature is active.
 * @returns {ReactNode} - Returns the children components if the feature is active, null otherwise.
 */
export const IfFeatureEnabledWithHotjar = ({ feature, children }) => {
  const featureId = feature;
  const isFeatureActive = useFeatureIsOn(featureId);
  useHotjarEvent(featureId, isFeatureActive);
  return <IfFeatureEnabled feature={featureId}>{children}</IfFeatureEnabled>;
};

IfFeatureEnabledWithHotjar.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const EVENT_NAMES = {
  BIDDING_RECEIVED: 'AddToWishlist',
};
