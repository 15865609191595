export const APPLICATION = {
  ON_HOLD: 'on-hold', // from application.state
  PENDING: 'pending', // from application.state
  DEACTIVATED: 'deactivated', // from application.state
  ACCEPTED: 'accepted', // from application.state
  FAILED: 'failed', // from application.state
  BIDDING: 'bidding', // from application.state, split into below client side states
  AWAITING: 'CLIENT_awaiting', // default state
  NO_BIDS: 'CLIENT_no_bids', // derived from bidding.bids & bidding.state
  HAS_BIDS: 'CLIENT_has_bids', // derived from bidding.bids
  MORTGAGE_INTEREST: 'mortgage_interest', // derived from bidding.state and bidding.mortgage.eligible
  SIGNED: 'signed',
  PPI_SIGNING: 'ppi_signing',
  PPI_DIRECT_DEBT: 'ppi_direct_debt',
  INSURANCE: 'insurance',
  MULTIPLE_APPLICATIONS_OVERVIEW: 'multiple-applications-overview',
};

export const WEBSITE_STATES = {
  WEBSITE_LOADING: 'WEBSITE_LOADING',
  APPLICATION_DEACTIVATED: 'APPLICATION_DEACTIVATED',
  BID_ACCEPTED: 'BID_ACCEPTED',
  BIDDING_ONGOING_NO_BIDS: 'BIDDING_ONGOING_NO_BIDS',
  BIDDING_FINISHED_NO_BIDS: 'BIDDING_FINISHED_NO_BIDS',
  BIDDING_ONGOING_HAS_BIDS: 'BIDDING_ONGOING_HAS_BIDS',
  BIDDING_FINISHED_HAS_BIDS: 'BIDDING_FINISHED_HAS_BIDS',
  NON_APPLICATION: 'NON_APPLICATION',
  NO_BIDS_ON_DECLINED: 'no_bids_on_decline',
};

export const BIDDING = {
  NOT_STARTED: 'not-started',
  STARTED: 'started',
  FINISHED: 'finished',
};

export const CREDITOR_ACCEPT = {
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
  UNDEFINED: undefined,
};

export const CRUMB_STATE = {
  PAST: 'past',
  ACTIVE: 'active',
  UPCOMING: 'upcoming',
};

export const INVITE_STATES = {
  ACTIVE: 'ACTIVE',
  DECLINED: 'DECLINED',
  REJECTED: 'REJECTED',
};

export const VISIBILITY_STATE = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};
