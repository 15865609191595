import 'whatwg-fetch';

/* eslint-disable */
import * as Endpoints from '_js/constants/Endpoints';
import { getAuthorizationToken } from '_js/utils/Authorization';
import { isDevelopment } from '_js/utils/environment';
import { PermissionDeniedError, FetchError } from '_js/constants/Errors';
/* eslint-enable */
import { getMimeType } from '_js/utils/contentType';

let urlEtags = {};

const clearEtags = () => {
  urlEtags = {};
};

export const getRequestObject = (
  url,
  method = 'GET',
  body = undefined,
  headers = new Headers(),
) => {
  const authToken = getAuthorizationToken();

  if (authToken) {
    headers.append('Authorization', `Bearer ${authToken}`);
  }

  const parameters = {
    method,
    headers,
    mode: 'cors',
    credentials: isDevelopment() ? 'same-origin' : 'include',
  };

  if (body && typeof body === 'object') {
    parameters.headers.append('Content-Type', 'application/json');
    parameters.body = JSON.stringify(body);
  }

  return new Request(url, parameters);
};

export const makeRequest = (options = {}) => {
  const url = options.endpoint.createUrl(options.urlParams);
  const EndpointError = options.endpoint.error;
  const requestHeaders = new Headers(options.headers || {});

  if (urlEtags[url]) {
    requestHeaders.append('If-None-Match', urlEtags[url]);
    requestHeaders.append('Cache-Control', 'private, must-revalidate');
  }

  const requestObject = getRequestObject(url, options.method, options.body, requestHeaders);
  return fetch(requestObject)
    .catch((error) => {
      throw new EndpointError('Endpoint error', error);
    })
    .then((response) => {
      if (response.status === 401) {
        throw new PermissionDeniedError();
      }
      if (response.status >= 400) {
        const fetchError = new FetchError();
        fetchError.status = response.status;

        return Promise.resolve(
          response.json().then((body) => {
            fetchError.type = body.details?.error?.name;
            throw fetchError;
          }),
        );
      }
      if (response.headers && response.headers.has('ETag')) {
        urlEtags[url] = response.headers.get('ETag');
      }

      if (response.headers && response.headers.has('content-type')) {
        const contentTypeHeader = response.headers.get('content-type');
        const mimeType = getMimeType(contentTypeHeader);

        switch (mimeType) {
          case 'application/json':
            return response.json();
          default:
            return null;
        }
      }

      return null;
    });
};

export const acceptBid = (applicationId, bidId, accountNumber) => {
  const options = {
    method: 'POST',
    endpoint: Endpoints.ACCEPT_BID,
    urlParams: { applicationId, bidId },
  };

  options.body = {};

  if (accountNumber) {
    options.body.bankAccount = accountNumber;
  }
  return makeRequest(options);
};

export const startPpiSigning = (applicationId, policyId, autogiro, skipLetters) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.START_PPI_SIGNING,
    body: {
      applicationId,
      policyId,
      autogiro,
      skipLetters,
    },
  });

export const startPpiSigningWithoutBankID = (
  applicationId,
  policyId,
  isAutogiro,
  skipLetters,
  bankAccount,
  paymentMethod,
) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.START_PPI_SIGNING_WITHOUT_BANKID,
    urlParams: { applicationId },
    body: {
      isAutogiro,
      policyId,
      skipLetters,
      paymentMethod: {
        gateway: paymentMethod,
        data: {
          iban: bankAccount,
        },
      },
    },
  });

export const ppiSignFIstatus = () =>
  makeRequest({
    endpoint: Endpoints.CHECK_USER_SIGNED,
  });

export const startLoginSigning = (payload) => {
  clearEtags();
  return makeRequest({
    method: 'POST',
    endpoint: Endpoints.START_LOGIN_SIGNING,
    urlParams: { type: payload.type },
    body: payload,
  });
};

export const ppiSigningStatus = (token) =>
  makeRequest({
    endpoint: Endpoints.PPI_SIGNING_STATUS,
    urlParams: { token },
  });

export const loginSigningStatus = (token) =>
  makeRequest({
    endpoint: Endpoints.LOGIN_SIGNING_STATUS,
    urlParams: { token },
  });

export const authorizeGoogleToken = (idToken) => {
  const options = {
    method: 'POST',
    endpoint: Endpoints.GOOGLE_LOGIN,
  };

  if (idToken) {
    options.body = { idToken };
  }

  return makeRequest(options);
};

export const skipAccountScraping = (applicationId, onHoldStep) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.ACCOUNT_SCRAPING_SKIP,
    urlParams: { applicationId },
    body: { onHoldStep },
  });

export const postAuthorizationCode = (applicationId, reportsGenerationJobId, requestedResources) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.COMPLETE_TINK,
    urlParams: { applicationId },
    body: {
      reportsGenerationJobId,
      requestedResources,
    },
  });

export const getMarketingContact = (applicationId) =>
  makeRequest({
    method: 'GET',
    endpoint: Endpoints.MARKETING_CONTACT,
    urlParams: { applicationId },
  });

export const getSessionId = (applicationId, brand, SSN) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.PREFILL_SESSION,
    urlParams: { applicationId },
    body: {
      brand,
      SSN,
    },
  });

export const subscribeNewsletter = (applicationId) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.SUBSCRIBE_NEWSLETTER,
    urlParams: { applicationId },
  });

export const fetchAcceptNewsletterStatus = (applicationId) =>
  makeRequest({
    method: 'GET',
    endpoint: Endpoints.ACCEPT_NEWSLETTER_STATUS,
    urlParams: { applicationId },
  });

export const getCreditCardOffers = (applicationId) =>
  makeRequest({
    method: 'GET',
    endpoint: Endpoints.CREDIT_CARD_OFFERS,
    urlParams: { applicationId },
  });

export const dryRunFilters = (applicationId, creditorIds) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.DRY_RUN_FILTERS,
    urlParams: { applicationId },
    body: {
      creditorIds,
    },
  });

export const postGjeldsregisteretConsent = (validUntil, email, mobilePhone) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.GJELDSREGISTERET_CONSENT,
    body: {
      validUntil,
      email,
      mobilePhone,
    },
  });

export const postMultipleApplicationsAuthorization = (customerId, applicationId) =>
  makeRequest({
    method: 'POST',
    endpoint: Endpoints.MULTIPLE_APPLICATIONS_AUTHORIZATION,
    urlParams: { customerId },
    body: {
      applicationId,
    },
  });

export const getIdKollenResponse = () =>
  makeRequest({
    method: 'GET',
    endpoint: Endpoints.IDKOLLEN_RESPONSE,
  });

export const getLogout = () =>
  makeRequest({
    method: 'GET',
    endpoint: Endpoints.LOGOUT,
  });
